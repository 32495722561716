var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var defaultTemplates = {
  HTMLMarker: "<p>Your custom HTML Marker</p>",
  reset: "Clear the map refinement",
  toggle: "Search as I move the map",
  redo: "Redo search here"
};
var _default = defaultTemplates;
exports.default = _default;
export default exports;