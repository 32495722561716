import * as _preact3 from "preact";

var _preact2 = "default" in _preact3 ? _preact3.default : _preact3;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _preact = _preact2;
/** @jsx h */

var GeoSearchButton = function GeoSearchButton(_ref) {
  var className = _ref.className,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      onClick = _ref.onClick,
      children = _ref.children;
  return (0, _preact.h)("button", {
    className: className,
    onClick: onClick,
    disabled: disabled
  }, children);
};

var _default = GeoSearchButton;
exports.default = _default;
export default exports;