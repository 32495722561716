import _index2 from "../../lib/utils/index.js";
import _connectQueryRules2 from "../../connectors/query-rules/connectQueryRules.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _index2;

var _connectQueryRules = _interopRequireDefault(_connectQueryRules2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var withUsage = (0, _index.createDocumentationMessageGenerator)({
  name: "query-rule-context"
});

var queryRuleContext = function queryRuleContext() {
  var widgetParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (!widgetParams.trackedFilters) {
    throw new Error(withUsage("The `trackedFilters` option is required."));
  }

  return _objectSpread(_objectSpread({}, (0, _connectQueryRules.default)(_index.noop)(widgetParams)), {}, {
    $$widgetType: "ais.queryRuleContext"
  });
};

var _default = queryRuleContext;
exports.default = _default;
export default exports;