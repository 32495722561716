import * as _preact3 from "preact";

var _preact2 = "default" in _preact3 ? _preact3.default : _preact3;

import _Template2 from "../Template/Template.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _preact = _preact2;

var _Template = _interopRequireDefault(_Template2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @jsx h */


var RelevantSort = function RelevantSort(_ref) {
  var cssClasses = _ref.cssClasses,
      templates = _ref.templates,
      isRelevantSorted = _ref.isRelevantSorted,
      isVirtualReplica = _ref.isVirtualReplica,
      refine = _ref.refine;
  return isVirtualReplica ? (0, _preact.h)("div", {
    className: cssClasses.root
  }, (0, _preact.h)(_Template.default, {
    templateKey: "text",
    templates: templates,
    rootProps: {
      className: cssClasses.text
    },
    data: {
      isRelevantSorted: isRelevantSorted
    }
  }), (0, _preact.h)("button", {
    type: "button",
    className: cssClasses.button,
    onClick: function onClick() {
      if (isRelevantSorted) {
        refine(0);
      } else {
        refine(undefined);
      }
    }
  }, (0, _preact.h)(_Template.default, {
    rootTagName: "span",
    templateKey: "button",
    templates: templates,
    data: {
      isRelevantSorted: isRelevantSorted
    }
  }))) : null;
};

var _default = RelevantSort;
exports.default = _default;
export default exports;