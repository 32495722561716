import _analytics2 from "./analytics/analytics.js";
import _breadcrumb2 from "./breadcrumb/breadcrumb.js";
import _clearRefinements2 from "./clear-refinements/clear-refinements.js";
import _configure2 from "./configure/configure.js";
import _currentRefinements2 from "./current-refinements/current-refinements.js";
import _answers2 from "./answers/answers.js";
import _configureRelatedItems2 from "./configure-related-items/configure-related-items.js";
import _dynamicWidgets2 from "./dynamic-widgets/dynamic-widgets.js";
import _index3 from "../lib/utils/index.js";
import _geoSearch2 from "./geo-search/geo-search.js";
import _hierarchicalMenu2 from "./hierarchical-menu/hierarchical-menu.js";
import _hits2 from "./hits/hits.js";
import _hitsPerPage2 from "./hits-per-page/hits-per-page.js";
import _index4 from "./index/index.js";
import _infiniteHits2 from "./infinite-hits/infinite-hits.js";
import _menu2 from "./menu/menu.js";
import _menuSelect2 from "./menu-select/menu-select.js";
import _numericMenu2 from "./numeric-menu/numeric-menu.js";
import _pagination2 from "./pagination/pagination.js";
import _panel2 from "./panel/panel.js";
import _places2 from "./places/places.js";
import _poweredBy2 from "./powered-by/powered-by.js";
import _queryRuleContext2 from "./query-rule-context/query-rule-context.js";
import _queryRuleCustomData2 from "./query-rule-custom-data/query-rule-custom-data.js";
import _rangeInput2 from "./range-input/range-input.js";
import _rangeSlider2 from "./range-slider/range-slider.js";
import _ratingMenu2 from "./rating-menu/rating-menu.js";
import _refinementList2 from "./refinement-list/refinement-list.js";
import _relevantSort2 from "./relevant-sort/relevant-sort.js";
import _searchBox2 from "./search-box/search-box.js";
import _sortBy2 from "./sort-by/sort-by.js";
import _stats2 from "./stats/stats.js";
import _toggleRefinement2 from "./toggle-refinement/toggle-refinement.js";
import _voiceSearch2 from "./voice-search/voice-search.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "analytics", {
  enumerable: true,
  get: function get() {
    return _analytics.default;
  }
});
Object.defineProperty(exports, "breadcrumb", {
  enumerable: true,
  get: function get() {
    return _breadcrumb.default;
  }
});
Object.defineProperty(exports, "clearRefinements", {
  enumerable: true,
  get: function get() {
    return _clearRefinements.default;
  }
});
Object.defineProperty(exports, "configure", {
  enumerable: true,
  get: function get() {
    return _configure.default;
  }
});
Object.defineProperty(exports, "currentRefinements", {
  enumerable: true,
  get: function get() {
    return _currentRefinements.default;
  }
});
Object.defineProperty(exports, "EXPERIMENTAL_answers", {
  enumerable: true,
  get: function get() {
    return _answers.default;
  }
});
Object.defineProperty(exports, "EXPERIMENTAL_configureRelatedItems", {
  enumerable: true,
  get: function get() {
    return _configureRelatedItems.default;
  }
});
Object.defineProperty(exports, "dynamicWidgets", {
  enumerable: true,
  get: function get() {
    return _dynamicWidgets.default;
  }
});
Object.defineProperty(exports, "geoSearch", {
  enumerable: true,
  get: function get() {
    return _geoSearch.default;
  }
});
Object.defineProperty(exports, "hierarchicalMenu", {
  enumerable: true,
  get: function get() {
    return _hierarchicalMenu.default;
  }
});
Object.defineProperty(exports, "hits", {
  enumerable: true,
  get: function get() {
    return _hits.default;
  }
});
Object.defineProperty(exports, "hitsPerPage", {
  enumerable: true,
  get: function get() {
    return _hitsPerPage.default;
  }
});
Object.defineProperty(exports, "index", {
  enumerable: true,
  get: function get() {
    return _index2.default;
  }
});
Object.defineProperty(exports, "infiniteHits", {
  enumerable: true,
  get: function get() {
    return _infiniteHits.default;
  }
});
Object.defineProperty(exports, "menu", {
  enumerable: true,
  get: function get() {
    return _menu.default;
  }
});
Object.defineProperty(exports, "menuSelect", {
  enumerable: true,
  get: function get() {
    return _menuSelect.default;
  }
});
Object.defineProperty(exports, "numericMenu", {
  enumerable: true,
  get: function get() {
    return _numericMenu.default;
  }
});
Object.defineProperty(exports, "pagination", {
  enumerable: true,
  get: function get() {
    return _pagination.default;
  }
});
Object.defineProperty(exports, "panel", {
  enumerable: true,
  get: function get() {
    return _panel.default;
  }
});
Object.defineProperty(exports, "places", {
  enumerable: true,
  get: function get() {
    return _places.default;
  }
});
Object.defineProperty(exports, "poweredBy", {
  enumerable: true,
  get: function get() {
    return _poweredBy.default;
  }
});
Object.defineProperty(exports, "queryRuleContext", {
  enumerable: true,
  get: function get() {
    return _queryRuleContext.default;
  }
});
Object.defineProperty(exports, "queryRuleCustomData", {
  enumerable: true,
  get: function get() {
    return _queryRuleCustomData.default;
  }
});
Object.defineProperty(exports, "rangeInput", {
  enumerable: true,
  get: function get() {
    return _rangeInput.default;
  }
});
Object.defineProperty(exports, "rangeSlider", {
  enumerable: true,
  get: function get() {
    return _rangeSlider.default;
  }
});
Object.defineProperty(exports, "ratingMenu", {
  enumerable: true,
  get: function get() {
    return _ratingMenu.default;
  }
});
Object.defineProperty(exports, "refinementList", {
  enumerable: true,
  get: function get() {
    return _refinementList.default;
  }
});
Object.defineProperty(exports, "relevantSort", {
  enumerable: true,
  get: function get() {
    return _relevantSort.default;
  }
});
Object.defineProperty(exports, "searchBox", {
  enumerable: true,
  get: function get() {
    return _searchBox.default;
  }
});
Object.defineProperty(exports, "sortBy", {
  enumerable: true,
  get: function get() {
    return _sortBy.default;
  }
});
Object.defineProperty(exports, "stats", {
  enumerable: true,
  get: function get() {
    return _stats.default;
  }
});
Object.defineProperty(exports, "toggleRefinement", {
  enumerable: true,
  get: function get() {
    return _toggleRefinement.default;
  }
});
Object.defineProperty(exports, "voiceSearch", {
  enumerable: true,
  get: function get() {
    return _voiceSearch.default;
  }
});
exports.EXPERIMENTAL_dynamicWidgets = void 0;

var _analytics = _interopRequireDefault(_analytics2);

var _breadcrumb = _interopRequireDefault(_breadcrumb2);

var _clearRefinements = _interopRequireDefault(_clearRefinements2);

var _configure = _interopRequireDefault(_configure2);

var _currentRefinements = _interopRequireDefault(_currentRefinements2);

var _answers = _interopRequireDefault(_answers2);

var _configureRelatedItems = _interopRequireDefault(_configureRelatedItems2);

var _dynamicWidgets = _interopRequireDefault(_dynamicWidgets2);

var _index = _index3;

var _geoSearch = _interopRequireDefault(_geoSearch2);

var _hierarchicalMenu = _interopRequireDefault(_hierarchicalMenu2);

var _hits = _interopRequireDefault(_hits2);

var _hitsPerPage = _interopRequireDefault(_hitsPerPage2);

var _index2 = _interopRequireDefault(_index4);

var _infiniteHits = _interopRequireDefault(_infiniteHits2);

var _menu = _interopRequireDefault(_menu2);

var _menuSelect = _interopRequireDefault(_menuSelect2);

var _numericMenu = _interopRequireDefault(_numericMenu2);

var _pagination = _interopRequireDefault(_pagination2);

var _panel = _interopRequireDefault(_panel2);

var _places = _interopRequireDefault(_places2);

var _poweredBy = _interopRequireDefault(_poweredBy2);

var _queryRuleContext = _interopRequireDefault(_queryRuleContext2);

var _queryRuleCustomData = _interopRequireDefault(_queryRuleCustomData2);

var _rangeInput = _interopRequireDefault(_rangeInput2);

var _rangeSlider = _interopRequireDefault(_rangeSlider2);

var _ratingMenu = _interopRequireDefault(_ratingMenu2);

var _refinementList = _interopRequireDefault(_refinementList2);

var _relevantSort = _interopRequireDefault(_relevantSort2);

var _searchBox = _interopRequireDefault(_searchBox2);

var _sortBy = _interopRequireDefault(_sortBy2);

var _stats = _interopRequireDefault(_stats2);

var _toggleRefinement = _interopRequireDefault(_toggleRefinement2);

var _voiceSearch = _interopRequireDefault(_voiceSearch2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @deprecated use dynamicWidgets */


var EXPERIMENTAL_dynamicWidgets = (0, _index.deprecate)(_dynamicWidgets.default, "use dynamicWidgets");
exports.EXPERIMENTAL_dynamicWidgets = EXPERIMENTAL_dynamicWidgets;
export default exports;
export const __esModule = exports.__esModule,
      analytics = exports.analytics,
      breadcrumb = exports.breadcrumb,
      clearRefinements = exports.clearRefinements,
      configure = exports.configure,
      currentRefinements = exports.currentRefinements,
      EXPERIMENTAL_answers = exports.EXPERIMENTAL_answers,
      EXPERIMENTAL_configureRelatedItems = exports.EXPERIMENTAL_configureRelatedItems,
      dynamicWidgets = exports.dynamicWidgets,
      geoSearch = exports.geoSearch,
      hierarchicalMenu = exports.hierarchicalMenu,
      hits = exports.hits,
      hitsPerPage = exports.hitsPerPage,
      index = exports.index,
      infiniteHits = exports.infiniteHits,
      menu = exports.menu,
      menuSelect = exports.menuSelect,
      numericMenu = exports.numericMenu,
      pagination = exports.pagination,
      panel = exports.panel,
      places = exports.places,
      poweredBy = exports.poweredBy,
      queryRuleContext = exports.queryRuleContext,
      queryRuleCustomData = exports.queryRuleCustomData,
      rangeInput = exports.rangeInput,
      rangeSlider = exports.rangeSlider,
      ratingMenu = exports.ratingMenu,
      refinementList = exports.refinementList,
      relevantSort = exports.relevantSort,
      searchBox = exports.searchBox,
      sortBy = exports.sortBy,
      stats = exports.stats,
      toggleRefinement = exports.toggleRefinement,
      voiceSearch = exports.voiceSearch;
const _EXPERIMENTAL_dynamicWidgets = exports.EXPERIMENTAL_dynamicWidgets;
export { _EXPERIMENTAL_dynamicWidgets as EXPERIMENTAL_dynamicWidgets };