var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var defaultTemplates = {
  home: "Home",
  separator: ">"
};
var _default = defaultTemplates;
exports.default = _default;
export default exports;