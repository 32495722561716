import * as _preact3 from "preact";

var _preact2 = "default" in _preact3 ? _preact3.default : _preact3;

import * as _classnames3 from "classnames";

var _classnames2 = "default" in _classnames3 ? _classnames3.default : _classnames3;

import _RefinementList2 from "../../components/RefinementList/RefinementList.js";
import _connectRefinementList2 from "../../connectors/refinement-list/connectRefinementList.js";
import _index2 from "../../lib/utils/index.js";
import _suit2 from "../../lib/suit.js";
import _defaultTemplates3 from "../search-box/defaultTemplates.js";
import _defaultTemplates4 from "./defaultTemplates.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _preact = _preact2;

var _classnames = _interopRequireDefault(_classnames2);

var _RefinementList = _interopRequireDefault(_RefinementList2);

var _connectRefinementList = _interopRequireDefault(_connectRefinementList2);

var _index = _index2;
var _suit = _suit2;

var _defaultTemplates = _interopRequireDefault(_defaultTemplates3);

var _defaultTemplates2 = _interopRequireDefault(_defaultTemplates4);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var withUsage = (0, _index.createDocumentationMessageGenerator)({
  name: "refinement-list"
});
var suit = (0, _suit.component)("RefinementList");
var searchBoxSuit = (0, _suit.component)("SearchBox");

var renderer = function renderer(_ref) {
  var containerNode = _ref.containerNode,
      cssClasses = _ref.cssClasses,
      templates = _ref.templates,
      searchBoxTemplates = _ref.searchBoxTemplates,
      renderState = _ref.renderState,
      showMore = _ref.showMore,
      searchable = _ref.searchable,
      searchablePlaceholder = _ref.searchablePlaceholder,
      searchableIsAlwaysActive = _ref.searchableIsAlwaysActive;
  return function (_ref2, isFirstRendering) {
    var refine = _ref2.refine,
        items = _ref2.items,
        createURL = _ref2.createURL,
        searchForItems = _ref2.searchForItems,
        isFromSearch = _ref2.isFromSearch,
        instantSearchInstance = _ref2.instantSearchInstance,
        toggleShowMore = _ref2.toggleShowMore,
        isShowingMore = _ref2.isShowingMore,
        hasExhaustiveItems = _ref2.hasExhaustiveItems,
        canToggleShowMore = _ref2.canToggleShowMore;

    if (isFirstRendering) {
      renderState.templateProps = (0, _index.prepareTemplateProps)({
        defaultTemplates: _defaultTemplates2.default,
        templatesConfig: instantSearchInstance.templatesConfig,
        templates: templates
      });
      renderState.searchBoxTemplateProps = (0, _index.prepareTemplateProps)({
        defaultTemplates: _defaultTemplates.default,
        templatesConfig: instantSearchInstance.templatesConfig,
        templates: searchBoxTemplates
      });
      return;
    }

    (0, _preact.render)((0, _preact.h)(_RefinementList.default, {
      createURL: createURL,
      cssClasses: cssClasses,
      facetValues: items,
      templateProps: renderState.templateProps,
      searchBoxTemplateProps: renderState.searchBoxTemplateProps,
      toggleRefinement: refine,
      searchFacetValues: searchable ? searchForItems : undefined,
      searchPlaceholder: searchablePlaceholder,
      searchIsAlwaysActive: searchableIsAlwaysActive,
      isFromSearch: isFromSearch,
      showMore: showMore && !isFromSearch && items.length > 0,
      toggleShowMore: toggleShowMore,
      isShowingMore: isShowingMore,
      hasExhaustiveItems: hasExhaustiveItems,
      canToggleShowMore: canToggleShowMore
    }), containerNode);
  };
};
/**
 * The refinement list widget is one of the most common widget that you can find
 * in a search UI. With this widget, the user can filter the dataset based on facets.
 *
 * The refinement list displays only the most relevant facets for the current search
 * context. The sort option only affects the facet that are returned by the engine,
 * not which facets are returned.
 *
 * This widget also implements search for facet values, which is a mini search inside the
 * values of the facets. This makes easy to deal with uncommon facet values.
 *
 * @requirements
 *
 * The attribute passed to `attribute` must be declared as an
 * [attribute for faceting](https://www.algolia.com/doc/guides/searching/faceting/#declaring-attributes-for-faceting)
 * in your Algolia settings.
 *
 * If you also want to use search for facet values on this attribute, you need to make it searchable using the [dashboard](https://www.algolia.com/explorer/display/) or using the [API](https://www.algolia.com/doc/guides/searching/faceting/#search-for-facet-values).
 */


var refinementList = function refinementList(widgetParams) {
  var _ref3 = widgetParams || {},
      container = _ref3.container,
      attribute = _ref3.attribute,
      operator = _ref3.operator,
      sortBy = _ref3.sortBy,
      limit = _ref3.limit,
      showMore = _ref3.showMore,
      showMoreLimit = _ref3.showMoreLimit,
      _ref3$searchable = _ref3.searchable,
      searchable = _ref3$searchable === void 0 ? false : _ref3$searchable,
      _ref3$searchablePlace = _ref3.searchablePlaceholder,
      searchablePlaceholder = _ref3$searchablePlace === void 0 ? "Search..." : _ref3$searchablePlace,
      _ref3$searchableEscap = _ref3.searchableEscapeFacetValues,
      searchableEscapeFacetValues = _ref3$searchableEscap === void 0 ? true : _ref3$searchableEscap,
      _ref3$searchableIsAlw = _ref3.searchableIsAlwaysActive,
      searchableIsAlwaysActive = _ref3$searchableIsAlw === void 0 ? true : _ref3$searchableIsAlw,
      _ref3$cssClasses = _ref3.cssClasses,
      userCssClasses = _ref3$cssClasses === void 0 ? {} : _ref3$cssClasses,
      _ref3$templates = _ref3.templates,
      templates = _ref3$templates === void 0 ? {} : _ref3$templates,
      transformItems = _ref3.transformItems;

  if (!container) {
    throw new Error(withUsage("The `container` option is required."));
  }

  var escapeFacetValues = searchable ? Boolean(searchableEscapeFacetValues) : false;
  var containerNode = (0, _index.getContainerNode)(container);
  var cssClasses = {
    root: (0, _classnames.default)(suit(), userCssClasses.root),
    noRefinementRoot: (0, _classnames.default)(suit({
      modifierName: "noRefinement"
    }), userCssClasses.noRefinementRoot),
    list: (0, _classnames.default)(suit({
      descendantName: "list"
    }), userCssClasses.list),
    item: (0, _classnames.default)(suit({
      descendantName: "item"
    }), userCssClasses.item),
    selectedItem: (0, _classnames.default)(suit({
      descendantName: "item",
      modifierName: "selected"
    }), userCssClasses.selectedItem),
    searchBox: (0, _classnames.default)(suit({
      descendantName: "searchBox"
    }), userCssClasses.searchBox),
    label: (0, _classnames.default)(suit({
      descendantName: "label"
    }), userCssClasses.label),
    checkbox: (0, _classnames.default)(suit({
      descendantName: "checkbox"
    }), userCssClasses.checkbox),
    labelText: (0, _classnames.default)(suit({
      descendantName: "labelText"
    }), userCssClasses.labelText),
    count: (0, _classnames.default)(suit({
      descendantName: "count"
    }), userCssClasses.count),
    noResults: (0, _classnames.default)(suit({
      descendantName: "noResults"
    }), userCssClasses.noResults),
    showMore: (0, _classnames.default)(suit({
      descendantName: "showMore"
    }), userCssClasses.showMore),
    disabledShowMore: (0, _classnames.default)(suit({
      descendantName: "showMore",
      modifierName: "disabled"
    }), userCssClasses.disabledShowMore),
    searchable: {
      root: (0, _classnames.default)(searchBoxSuit(), userCssClasses.searchableRoot),
      form: (0, _classnames.default)(searchBoxSuit({
        descendantName: "form"
      }), userCssClasses.searchableForm),
      input: (0, _classnames.default)(searchBoxSuit({
        descendantName: "input"
      }), userCssClasses.searchableInput),
      submit: (0, _classnames.default)(searchBoxSuit({
        descendantName: "submit"
      }), userCssClasses.searchableSubmit),
      submitIcon: (0, _classnames.default)(searchBoxSuit({
        descendantName: "submitIcon"
      }), userCssClasses.searchableSubmitIcon),
      reset: (0, _classnames.default)(searchBoxSuit({
        descendantName: "reset"
      }), userCssClasses.searchableReset),
      resetIcon: (0, _classnames.default)(searchBoxSuit({
        descendantName: "resetIcon"
      }), userCssClasses.searchableResetIcon),
      loadingIndicator: (0, _classnames.default)(searchBoxSuit({
        descendantName: "loadingIndicator"
      }), userCssClasses.searchableLoadingIndicator),
      loadingIcon: (0, _classnames.default)(searchBoxSuit({
        descendantName: "loadingIcon"
      }), userCssClasses.searchableLoadingIcon)
    }
  };
  var specializedRenderer = renderer({
    containerNode: containerNode,
    cssClasses: cssClasses,
    templates: templates,
    searchBoxTemplates: {
      submit: templates.searchableSubmit,
      reset: templates.searchableReset,
      loadingIndicator: templates.searchableLoadingIndicator
    },
    renderState: {},
    searchable: searchable,
    searchablePlaceholder: searchablePlaceholder,
    searchableIsAlwaysActive: searchableIsAlwaysActive,
    showMore: showMore
  });
  var makeWidget = (0, _connectRefinementList.default)(specializedRenderer, function () {
    return (0, _preact.render)(null, containerNode);
  });
  return _objectSpread(_objectSpread({}, makeWidget({
    attribute: attribute,
    operator: operator,
    limit: limit,
    showMore: showMore,
    showMoreLimit: showMoreLimit,
    sortBy: sortBy,
    escapeFacetValues: escapeFacetValues,
    transformItems: transformItems
  })), {}, {
    $$widgetType: "ais.refinementList"
  });
};

var _default = refinementList;
exports.default = _default;
export default exports;