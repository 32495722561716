var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var defaultTemplates = {
  item: "<a class=\"{{cssClasses.link}}\" href=\"{{url}}\">" + "<span class=\"{{cssClasses.label}}\">{{label}}</span>" + "<span class=\"{{cssClasses.count}}\">{{#helpers.formatNumber}}{{count}}{{/helpers.formatNumber}}</span>" + "</a>",
  showMoreText: "\n    {{#isShowingMore}}\n      Show less\n    {{/isShowingMore}}\n    {{^isShowingMore}}\n      Show more\n    {{/isShowingMore}}\n  "
};
var _default = defaultTemplates;
exports.default = _default;
export default exports;